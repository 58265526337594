import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit, Inject, TemplateRef, ViewContainerRef, ViewChildren, QueryList } from '@angular/core';

import * as Dropzone from 'dropzone';

import { Subscription } from 'rxjs';

import { DropzoneComponent } from 'ngx-dropzone-wrapper';

import { DynamicFormDzService } from '@app/core/dynamic-form/dynamic-form-dz-field/dynamic-form-dz-service';
import { UtilService } from '../../service/util.service';
import { DropzoneField } from '../field/field-dropzone';
import { ApiService } from '../../service/api.service';
import { CFG } from './../../config/app-config.constants';
import { WINDOW } from './../../window-factory';
import { ToastService } from '@app/core/service';

@Component({
  selector: 'app-dynamic-form-dz-field',
  templateUrl: './dynamic-form-dz-field.component.html',
  styleUrls: ['./dynamic-form-dz-field.component.scss']
})
export class DynamicFormDzFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dz') private dzElRef: ElementRef;
  @ViewChild(DropzoneComponent) drpzone: DropzoneComponent;
  @ViewChildren('preview') preview:QueryList<ElementRef>;
  @Input() field: DropzoneField;

  private isEnableDeteleFile = true;
  private dz: any;
  private numberOfSendedFiles;
  private numberOfWasSendedFiles;
  dropzoneWasSendedSubscription: Subscription;
  dropzoneLoadFiledSubscription: Subscription;
  drozoneWasSendedWithFormDataSubscription: Subscription;
  dropzonePurgeFilesSubscription: Subscription;
  constructor(
    public dynamicFormDzService: DynamicFormDzService,
    private utilSvc: UtilService,
    private apiSvc: ApiService,
    private toastSvc: ToastService,
    @Inject(WINDOW) private window: Window) { }

  ngOnInit() {
    this.dz = this.drpzone.directiveRef.dropzone();
    // Send files to server
    this.dropzoneWasSendedSubscription = this.dynamicFormDzService.DropzoneWasSendedSubject.subscribe((data) => {
      const files = this.drpzone.directiveRef.dropzone().getQueuedFiles();

      // if doesnt exist file then it can return
      this.numberOfSendedFiles = files.length;
      this.numberOfWasSendedFiles = 0;
      if (this.numberOfSendedFiles === 0) {
        this.dynamicFormDzService.DropzoneDoneUploadSubject.next({ success: true, files: 0 });
        return;
      }

      // setting the options and sendind the files
      this.drpzone.directiveRef.dropzone().options.headers = this.utilSvc.buildHttpHeaders(true);
      this.drpzone.directiveRef.dropzone().options.url = this.utilSvc.buildUrl(data.path);
      if (data.formData) {
        this.drpzone.directiveRef.dropzone().options.params = data.formData;
      }
      this.dynamicFormDzService.queuedFileNumber = 0;
      this.drpzone.directiveRef.dropzone().processQueue();
    });


    this.dropzonePurgeFilesSubscription =
      this.dynamicFormDzService.DropzonePurgeFilesSubject.subscribe(() => {
        this.drpzone.directiveRef.dropzone().files = [];
      });
  }

  ngAfterViewInit() {
    if (this.field.customPreview) {
      this.field.config.previewTemplate = this.getPreviewTemplate(0);
    }

    this.dz = this.drpzone.directiveRef.dropzone();

    // Load files from server
    this.dropzoneLoadFiledSubscription =
      this.dynamicFormDzService.DropzoneLoadFilesSubject.subscribe((data: { url: string, files: File[] }) => {
        this.loadFiles(data.url, data.files);
      });

    this.dynamicFormDzService.DropzoneLoadFileSubject.subscribe((data: { url: string, file: string, id: number, type: string }) => {
      this.loadFile(data.url, data.file, data.id, data.type);
    });
  }

  ngOnDestroy() {
    this.isEnableDeteleFile = false;
    this.dropzoneWasSendedSubscription.unsubscribe();
    this.dropzoneLoadFiledSubscription.unsubscribe();
    this.dropzonePurgeFilesSubscription.unsubscribe();
  }


  // Update dropzone with loaded files from server
  private loadFiles(url: string, files: File[]) {
    const dzOpts = this.dz.options;
    for (const file of files) {
      const mockFile: {
        status: string,
        name: string,
        dataURL: string,
        id?: number
        previewElement?: Element,
      } = {
          status: Dropzone.SUCCESS,
          name: file.Name,
          dataURL: `${url}/${file.Name}`,
          id: file.Id
        };

      this.drpzone.directiveRef.dropzone().emit('addedfile', mockFile);

      if (this.field.config.acceptedFiles === 'image/jpeg,image/png,image/jpg') {
        this.dz.createThumbnailFromUrl(
          mockFile,
          dzOpts.thumbnailWidth,
          dzOpts.thumbnailHeight,
          dzOpts.thumbnailMethod,
          true,
          (dataURL) => this.dz.emit('thumbnail', mockFile, dataURL),
          'anonymous'
        );
      }

      const previewEl = mockFile.previewElement;

      previewEl.querySelector('.dz-size')['style'].visibility = 'hidden';
      previewEl.querySelector('.dz-progress')['style'].visibility = 'hidden';
      previewEl.querySelector('.dz-details')['style'].cursor = 'pointer';

      if (file.Name && file.Name.includes('.pdf')) {
        previewEl.querySelector('.pdf')['style'].display = 'block';
      }

      // Open the file in new tab
      previewEl.addEventListener('click', function() {
        this.window.open(`${url}/${file.Name}`, '_blank');
      }.bind(this));
      this.drpzone.directiveRef.dropzone().emit('complete', mockFile);
      this.drpzone.directiveRef.dropzone().files.push(mockFile);
    }
  }

  private loadFile(url: string, file: string, id: number, type: string) {
    const dzOpts = this.dz.options;
    dzOpts.thumbnailWidth = 600;
    dzOpts.thumbnailHeight = 600;
    dzOpts.thumbnailMethod = 'contain';
    const mockFile: {
      status: string,
      name: string,
      dataURL: string,
      id?: number
      previewElement?: Element,
    } = {
        status: Dropzone.SUCCESS,
        name: file,
        dataURL: `${url}${file}`,
        id: id
      };

    this.dz.emit('addedfile', mockFile);

    // Collect the mockfiles into the files.
    this.dz.files.push(mockFile);

    if (type === 'image') {
      this.dz.createThumbnailFromUrl(
        mockFile,
        dzOpts.thumbnailWidth,
        dzOpts.thumbnailHeight,
        dzOpts.thumbnailMethod,
        true,
        (dataURL) => this.dz.emit('thumbnail', mockFile, dataURL),
        'anonymous'
      );
    }
    const previewEl = mockFile.previewElement;

    // Hide the size indicator
    previewEl.querySelector('.dz-size')['style'].visibility = 'hidden';
    this.dz.emit('complete', mockFile);
  }

  onUploadError(data) {
    if (data[1] && data[1].data) {
      data[0].previewTemplate.querySelector('.dz-error-message span').innerText = data[1].data.message;
    }
    if (data[1] === 'You can not upload any more files.') {
      this.toastSvc.error('You can not upload any more files. The limit is 10');
    }
  }

  onUploadSuccess(data) {
    this.numberOfWasSendedFiles++;
    if (this.numberOfSendedFiles === this.numberOfWasSendedFiles) {
      this.dynamicFormDzService.DropzoneDoneUploadSubject.next({ success: true, files: this.numberOfSendedFiles });
    }
  }

  public onRemovedFile(image) {
    if (this.field.base64Mode) {
      this.dynamicFormDzService.files = this.drpzone.directiveRef.dropzone().files;
    }

    // Decrease number of queued files
    if (image.status === 'queued' || image.status === 'error') {
      this.dynamicFormDzService.queuedFileNumber--;
      this.dynamicFormDzService.DropzoneNumberOfImagesChangedSubject.next(this.dynamicFormDzService.queuedFileNumber);
    }

    // If image hasnt id then cant delete it.
    if (!image.id) {
      return;
    }

    this.apiSvc.post(UtilService.pathIdDelete(this.field.path, image.id)).subscribe(
      () => {
        this.toastSvc.success(CFG.msg.M007);
        return true;
      },
      (err) => {
        // If deleting failed, we set back the image and show an error message
        this.drpzone.directiveRef.dropzone()('addedfile', image);
        image.previewElement.querySelector('.dz-size')['style'].visibility = 'hidden';
        this.drpzone.directiveRef.dropzone()('complete', image);

        // Error message
        this.toastSvc.error(CFG.error.E000);
        return false;
      }
    );
  }

  public onAddedFile(file) {
    // If the file is added at right now, then increase the queued files number
    if (file.status === 'added') {
      this.dynamicFormDzService.queuedFileNumber++;
      this.dynamicFormDzService.DropzoneNumberOfImagesChangedSubject.next(this.dynamicFormDzService.queuedFileNumber);
    }

    if (this.field.base64Mode) {
      this.dynamicFormDzService.files = this.drpzone.directiveRef.dropzone().files;
    }
  }

  private getPreviewTemplate(index: number) {
    return this.preview.toArray()[index].nativeElement.innerHTML;
  }
}
