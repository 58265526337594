import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { ManageSidebarFilterService } from '@app/core/service/manage-sidebar-filter.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

enum DateMode {
  single = 'single',
  range = 'range',
  rangeFrom = 'rangeFrom',
  rangeTo = 'rangeTo',
}

const formatForMask = 'DDMMYYYY';

@Component({
  selector: 'app-custom-date-field',
  templateUrl: './custom-date-field.component.html',
  styleUrls: ['./custom-date-field.component.scss']
})
export class CustomDateFieldComponent implements OnInit, OnChanges {
  @Input()
  model = null;

  @Input()
  name = 'control';

  @Input()
  selectMode = DateMode.single;

  @Input()
  placeholder = '';

  @Output()
  changeDate = new EventEmitter();

  customPatterns = { '0': { pattern: new RegExp('\[0-9\]')} };
  mask = '';
  maskModel = '';
  isInput = false;
  showMaskTyped = false;
  dateIsInvalid = false;

  private separator = '/';

  constructor(
    public manageSidebarSvc: ManageSidebarFilterService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.separator = moment.localeData(this.translate.currentLang).longDateFormat('L').slice(2, 3);
    this.mask = `00${this.separator}00${this.separator}0000`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && !this.isInput) {
      this.setMaskModel(changes.model.currentValue);
      this.isInput = false;
    }
  }

  afterPickerOpen() {
    this.manageSidebarSvc.isOpenModal = true;
  }

  afterPickerClosed() {
    this.manageSidebarSvc.isOpenModal = false;
  }

  onModelChange(value: any) {
    this.setMaskModel(value);
    this.changeDate.emit(this.model);
  }

  onClearDate() {
    this.maskModel = '';
    this.onInputMask();
  }

  onInputMask(event?) {
    if (!(this.selectMode === DateMode.rangeFrom || this.selectMode === DateMode.rangeTo)) {
      return;
    }

    if (!Array.isArray(this.model)) {
      this.model = [];
    }

    const strDate = this.maskModel.split(this.separator).join('/'),
          momentDate = moment(strDate, 'DD/MM/YYYY');

    this.model[this.index] = momentDate.isValid() ? momentDate : null;
    this.dateIsInvalid = !momentDate.isValid() && !!strDate.length;
    this.model = [...this.model];

    this.isInput = true;
    this.changeDate.emit(this.model);
  }

  onBlur() {
    this.isInput = false;
    this.showMaskTyped = false;
  }

  onFocus() {
    this.showMaskTyped = true;
  }

  private setMaskModel(value: any) {
    const momentDate = value && value[this.index];

    if (!momentDate) {
      this.maskModel = '';
      return;
    }

    if (momentDate) {
      this.maskModel = momentDate.format ? momentDate.format(formatForMask) : moment(momentDate).format(formatForMask);
    }
  }

  private get index(): number {
    return this.selectMode === DateMode.rangeFrom ? 0 : 1;
  }

  // onClearDate() {
  //   if (this.model === null || this.model === undefined) {
  //     return;
  //   }

  //   if (this.selectMode === DateMode.single || this.selectMode === DateMode.range) {
  //     this.onModelChange(this.model = null);
  //     return;
  //   }

  //   const index = this.selectMode === DateMode.rangeFrom ? 0 : 1;
  //   this.model[index] = null;
  //   this.onModelChange(this.model = [...this.model]);
  // }
}
