import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { Supplier } from '../model';

export const SupplierService = new InjectionToken<BaseFeatureModuleService<Supplier>>('SupplierService');

export function supplierSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<Supplier> {
    return new BaseFeatureModuleService<Supplier>(apiSvc, 'supplier',
    {
        getItem: { expand: 'Address,SupplierContacts' }
    });
}
