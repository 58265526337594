import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
  Input,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { finalize, take, map } from 'rxjs/operators';
import {
  BaseFeatureModuleService,
  CFG,
  ServiceBelt,
  ServiceBeltService,
  BeltCategory,
  SettingsService,
  ToastService,
  UtilService,
  WINDOW,
  Park,
  AppService,
} from '@app/core';

import { SharedDatatableComponent } from '../../datatable/shared-datatable.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDatePipe } from '@app/core/pipes/localized-date.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import * as _ from 'lodash';
import * as moment from 'moment';
import { BaseComponent } from '@app/shared/class/base-component.class';
import { LocalizedComponent } from '@app/shared/class/localized-component.class';

@Component({
  selector: 'app-shared-belt-service-list',
  templateUrl: './belt-service-list.component.html',
  styleUrls: ['./belt-service-list.component.scss'],
})
export class BeltServiceListComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @ViewChild(SharedDatatableComponent)
  private datatable: SharedDatatableComponent;
  @ViewChild('descriptionTpl')
  descriptionTpl: TemplateRef<any>;
  @ViewChild('statusTpl')
  statusTpl: TemplateRef<any>;
  @ViewChild('actionTpl')
  actionTpl: TemplateRef<any>;
  @ViewChild('assignedTpl')
  assignedTpl: TemplateRef<any>;
  @ViewChild('avatarTpl')
  avatarTpl: TemplateRef<any>;
  @ViewChild('formFilter') formFilter: NgForm;

  rows: ServiceBelt[] = [];
  items: ServiceBelt[] = [];
  _meta: any = {};
  notFirstTime = false;
  columns = [];
  loading = true;
  showTable = false;
  waitExport = false;

  hasUpdatePermission = false;
  hasRepairBeltService = false;
  equipmentType = 0;

  filterObject: any;
  private ls: any;
  private lsKey: string;
  private selectedFilterValues: any;
  sidebarFiltersOpened = false;

  // Subscription
  routeDataSubscr: Subscription;
  localizedComp: LocalizedComponent;

  constructor(
    protected appSvc: AppService,
    protected route: ActivatedRoute,
    private router: Router,
    private localize: LocalizeRouterService,
    protected chdRef: ChangeDetectorRef,
    @Inject(ServiceBeltService)
    private moduleTypeSvc: BaseFeatureModuleService<ServiceBelt>,
    private toastSvc: ToastService,
    private settingsSvc: SettingsService,
    private spinnerSvc: NgxSpinnerService,
    private translate: TranslateService,
    @Inject(WINDOW) private window: Window,
    public utilSvc: UtilService
  ) {
    super(appSvc, chdRef, route);
    this.ls = this.window.localStorage;
    this.initFilterObject();
    this._meta = {
      parkId: -1,
      createdAtDateFrom: '',
      createdAtDateTo: '',
      dueDateFrom: '',
      dueDateTo: '',
      assignedToUserId: -1,
      reportedByUserId: -1,
      search: '',
      categoryId: -1,
      checkTypeId: -1,
    };

    this.localizedComp = new LocalizedComponent(settingsSvc, translate);
  }

  ngOnInit(): void {
    this.getDependencies();
    this.equipmentType = this.route.snapshot.data.equipmentType;
    this.lsKey = `beltServiceList-${this.equipmentType}`;
    this.hasUpdatePermission = this.settingsSvc.userHasPermission(
      'updateBeltService'
    );

    this.hasRepairBeltService = this.settingsSvc.userHasPermission(
      'repairBeltService'
    );

    this.selectedFilterValues = this.ls.selectedFilters
      ? JSON.parse(this.ls.selectedFilters)
      : {};
    this.selectedFilterValues[this.lsKey]
      ? (this.filterObject = this.selectedFilterValues[this.lsKey])
      : // tslint:disable-next-line:no-unused-expression
        this.filterObject;

    this.spinnerSvc.show();
    // Build table columns
    this.columns = [
      {
        name: this.translate.instant('beltServiceList.columnPark'),
        prop: 'Belt.Park.Name',
        draggable: false,
        cellClass: 'mobile',
      },
      {
        name: this.translate.instant('beltServiceList.columnName'),
        prop: 'Belt.Name',
        draggable: false,
      },
      {
        name: this.translate.instant('beltServiceList.columnBarcode'),
        prop: 'Belt.Barcode',
        draggable: false,
        cellClass: 'mobile',
      },
      {
        name: this.translate.instant('beltServiceList.columnCategory'),
        prop: 'Category',
        draggable: false,
        cellClass: 'mobile',
      },
      {
        name: this.translate.instant('beltServiceList.columnReset'),
        prop: 'CheckType',
        draggable: false,
        cellClass: 'mobile',
      },
      {
        name: this.translate.instant('beltServiceList.columnReportedBy'),
        name2: this.translate.instant('beltServiceList.columnReportedAt'),
        prop1: 'Reporter.Name',
        prop2: 'DateAdded',
        cellTemplate: this.avatarTpl,
        draggable: false,
        cellClass: 'mobile',
        headerTemplate: this.datatable.splittedHeaderTpl
      },
      {
        name: this.translate.instant('beltServiceList.columnAssignedBy'),
        name2: this.translate.instant('beltServiceList.columnAssignedAt'),
        prop1: 'AssignedTo.Name',
        prop2: 'DueDate',
        cellTemplate: this.assignedTpl,
        draggable: false,
        cellClass: 'mobile',
        headerTemplate: this.datatable.splittedHeaderTpl
      },
      {
        name: '',
        draggable: false,
        cellTemplate: this.actionTpl,
        notResponsiveTitle: true,
        width: 130,
        maxWidth: 130,
      },
    ];

    // Get records.
    const params = {
      expand: 'Category,Reporter,CheckType,Belt.Park,AssignedTo',
      'equipment-type': `${this.equipmentType}`,
    };
    this.moduleTypeSvc
      .getItemsWithParams({ params })
      .pipe(
        take(1),
        // Hide the loading indicator
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (items: ServiceBelt[]) => {
          this.rows = items;
          this.items = items;
          this.filterRows();
          this.notFirstTime = true;
          this.spinnerSvc.hide();
          this.showTable = true;
        },
        (err: HttpErrorResponse) => {
          if (err && err.status !== 401) {
            // On error show some error message.
            this.toastSvc.error(CFG.error.E000);
          }
        }
      );
  }

  onChangeDateFilter(value, key: string) {
    this.filterObject[key] = value;
  }

  filterRows() {
    this.onFilterCloseSidebar();
    let results = Object.assign([], this.items);

    if (+this.filterObject.parkId !== -1) {
      results = _.filter(results, (i) => {
        return i.Belt.Park.Id === +this.filterObject.parkId;
      });
    }

    if (+this.filterObject.beltCategory !== -1) {
      results = _.filter(results, (i) => {
        return i.Belt.CategoryId === +this.filterObject.beltCategory;
      });
    }

    if (+this.filterObject.checkType !== -1) {
      results = _.filter(results, (i) => {
        return i.CheckTypeId === +this.filterObject.checkType;
      });
    }

    if (+this.filterObject.reporter !== -1) {
      results = _.filter(results, (i) => {
        return i.ReporterId === +this.filterObject.reporter;
      });
    }

    if (+this.filterObject.assign !== -1) {
      results = _.filter(results, (i) => {
        return i.AssignedId === +this.filterObject.assign;
      });
    }

    if (this.filterObject.dateCreate && this.filterObject.dateCreate[0]) {
      results = _.filter(results, (i) => {
        return moment(this.localizedComp.getLocalizedDate(i.DateAdded)).isSameOrAfter(
          this.filterObject.dateCreate[0],
          'day'
        );
      });
    }

    if (this.filterObject.dateCreate && this.filterObject.dateCreate[1]) {
      results = _.filter(results, (i) => {
        return moment(this.localizedComp.getLocalizedDate(i.DateAdded)).isSameOrBefore(
          this.filterObject.dateCreate[1],
          'day'
        );
      });
    }

    if (this.filterObject.dateDue && this.filterObject.dateDue[0]) {
      results = _.filter(results, (i) => {
        return moment(this.localizedComp.getLocalizedDate(i.DueDate)).isSameOrAfter(
          this.filterObject.dateDue[0],
          'day'
        );
      });
    }

      if (this.filterObject.dateDue && this.filterObject.dateDue[1]) {
        results = _.filter(results, (i) => {
          return moment(this.localizedComp.getLocalizedDate(i.DueDate)).isSameOrBefore(
            this.filterObject.dateDue[1],
            'day'
          );
        });
      }

    if (this.filterObject.like !== '') {
      results = _.filter(
        results,
        (item: ServiceBelt) =>
          item.Belt.Name.includes(this.filterObject.like) ||
          item.Belt.Barcode.includes(this.filterObject.like)
      );
      if (
        results.length === 1 &&
        results[0].Belt.Barcode === this.filterObject.like &&
        this.notFirstTime
      ) {
        this.router.navigate([
          this.localize.translateRoute('/app/equipment/service/repair/'),
          results[0].BeltId,
        ]);
      }
    }

    this.rows = results;
    this.serverSideFilter();
    setTimeout(() => {
      this.fixBugColumnResize();
    }, 1);
  }

  private serverSideFilter() {
    this._meta.createdAtDateFrom =
      this.filterObject.dateCreate && this.filterObject.dateCreate[0]
        ? moment(this.filterObject.dateCreate[0]).format('YYYY-MM-DD')
        : '';

    this._meta.createdAtDateTo =
      this.filterObject.dateCreate && this.filterObject.dateCreate[1]
        ? moment(this.filterObject.dateCreate[1]).format('YYYY-MM-DD')
        : '';

    this._meta.dueDateFrom =
      this.filterObject.dateDue && this.filterObject.dateDue[0]
        ? moment(this.filterObject.dateDue[0]).format('YYYY-MM-DD')
        : '';

    this._meta.dueDateTo =
      this.filterObject.dateDue && this.filterObject.dateDue[1]
        ? moment(this.filterObject.dateDue[1]).format('YYYY-MM-DD')
        : '';

    this._meta.assignedToUserId = this.filterObject.assign;

    this._meta.reportedByUserId = this.filterObject.reporter;

    this._meta.parkId = this.filterObject.parkId;
    this._meta.search = this.filterObject.like;
    this._meta.categoryId = this.filterObject.beltCategory;
    this._meta.checkTypeId = this.filterObject.checkType;

    // Remembering the park filter value
    this.selectedFilterValues[this.lsKey] = this.filterObject;
    this.ls.selectedFilters = JSON.stringify(this.selectedFilterValues);
  }

  private onFilterCloseSidebar() {
    this.sidebarFiltersOpened = true;
    this.chdRef.detectChanges();
    this.sidebarFiltersOpened = false;
  }

  fixBugColumnResize() {
    if (this.datatable && this.datatable.recalculate) {
      this.datatable.recalculate();
      window.dispatchEvent(new Event('resize'));
    }
  }

  ngOnDestroy() {
    this.routeDataSubscr.unsubscribe();
  }

  isDeprecated(date) {
    return UtilService.isDeprecatedDate(date);
  }

  get parkOptions() {
    return this.getDependencyByKey('parks', []);
  }

  get beltCategoryOptions() {
    return this.getDependencyByKey('beltCategories', []);
  }

  get userOptions() {
    return this.getDependencyByKey('users', []);
  }

  get beltServiceCheckTypes() {
    return this.getDependencyByKey('beltServiceCheckTypes', []);
  }

  exportXLS() {
    this.waitExport = true;
    const _tmpMeta = _.pick(this._meta, [
      'parkId',
      'search',
      'categoryId',
      'reportedByUserId',
      'checkTypeId',
      'createdAtDateFrom',
      'createdAtDateTo',
      'dueDateFrom',
      'dueDateTo',
      'assignedToUserId',
    ]);
    this.moduleTypeSvc
      .export({ 'equipment-type': `${this.equipmentType}`, ..._tmpMeta })
      .pipe(finalize(() => (this.waitExport = false)))
      .subscribe(
        (data) => {
          this.utilSvc.saveXLS(data);
        },
        (err: HttpErrorResponse) => {
          if (err && err.status !== 401) {
            // On error show some error message.
            this.toastSvc.error(CFG.error.E000);
          }
        }
      );
  }

  onClearFilters() {
    this.initFilterObject();
  }

  private initFilterObject() {
    this.filterObject = {
      parkId: -1,
      dateCreate: null,
      dateDue: null,
      like: '',
      beltCategory: -1,
      checkType: -1,
      reporter: -1,
      assign: -1,
    };
  }
}
